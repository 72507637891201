<template>
  <el-form ref="formRef"
           label-width="100px"
           :model="form"
           :rules="rules"
           hide-required-asterisk
           @submit.native.prevent
           size="small" >
    <div class="content">
      <el-form-item label="姓名" prop="username">
        <el-input v-model="form.username" clearable placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="身份证号" prop="idCardNo">
        <el-input v-model="form.idCardNo" @change="idCardNoChange" clearable placeholder="请输入身份证号"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-radio-group v-model="form.gender">
          <template v-for="(item,index) in range">
            <el-radio :key="index" :label="item.value">{{ item.label }}</el-radio>
          </template>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="年龄" prop="age">
        <el-input v-model="form.age" clearable placeholder="请输入年龄"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phoneNo">
        <el-input v-model="form.phoneNo" clearable placeholder="请输入微信授权手机号"></el-input>
      </el-form-item>
      <el-form-item label="医保卡号" prop="medicalCardNo">
        <el-input v-model="form.medicalCardNo" clearable placeholder="请输入医保卡号"></el-input>
      </el-form-item>
      <el-form-item label="出生日期" prop="birth">
        <el-date-picker
            v-model="form.birth"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="管理医生" prop="docId">
        <el-select v-model="form.docId" clearable placeholder="请选择管理医生">
          <el-option
              v-for="item in doctor_list"
              :key="item.id"
              :label="item.docName"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import {IdCard} from "@/utils/IdCard";
export default {
  name: "basic-info",
  data() {
    return  {
      form: {
        id: "",
        username: "",
        idCardNo: "",
        medicalCardNo: "",
        birth: "",
        gender: "",
        age: "",
        phoneNo: "",
        docId: "",
      },
      rules: {
        username: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        // medicalCardNo: [
        //   { required: true, message: '请输入医保卡号', trigger: 'blur' },
        // ],
        idCardNo: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: "身份证号不合法" }
        ],
        birth: [
          { required: true, message: '请选择出生日期', trigger: 'blur' },
        ],
        gender: [
          { required: true, message: '请选择性别', trigger: 'blur' },
        ],
        age: [
          { required: true, message: '请输入年龄', trigger: 'blur' },
        ],
        phoneNo: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^((13[0-9])|(14[0,1,4-9])|(15[0-3,5-9])|(16[2,5,6,7])|(17[0-8])|(18[0-9])|(19[0-3,5-9]))\d{8}$/, message: '手机号不正确'}
        ],
        docId: [
          { required: true, message: '请输入管理医生', trigger: 'blur' },
        ],
      },
      doctor_list: [],
      range: [
        {
          value: '1',
          label: "男",
        },
        {
          value: '0',
          label: "女",
        },
      ],
    }
  },
  inject: ["userId","docId"],
  created() {
    this.doctorlistFn();
    if(this.$route.query.userId) {
      this.get_details();
    }
  },
  mounted() {

  },
  methods: {
    get_details() {
      this.$get("colondoc/patient",{
        patientId: this.$route.query.userId,
        docId: this.$route.query.docId
      })
        .then(res => {
          if (res) {
            let data_ = res.data;
            this.form = data_;
          }
        })
    },
    onSubmit() {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validate((valid) => {
          if(valid) {
            this.$post("colondoc/save",this.form)
              .then(res => {
                if(res) {
                  let data_ = res.data;
                  let obj = {
                    ...this.form
                  }
                  obj["id"] = data_.patientId;
                  resolve(obj);
                }else {
                  reject(valid);
                }
              })
              .catch(err => {
                reject(err);
              })
          }else {
            reject(valid);
          }
        })
      })
    },
    idCardNoChange(e) {
      this.$refs.formRef.validateField("idCardNo",(valid) => {
        if(!valid) {
          this.form.gender = IdCard(e,2);
          this.form.age = IdCard(e,3);
          this.form.birth = IdCard(e,1);
        }else {
          this.form.gender = "";
          this.form.age = "";
          this.form.birth = "";
        }
      })
    },
    doctorlistFn() {
      this.$get(`gp/getDoctorList/${'0'}`)
          .then(res => {
            if(res) {
              let data_ = res.data;
              this.doctor_list = data_;
            }
          })
    },
  }
}
</script>

<style lang="scss" scoped>
.el-form{
  .content{
    width: 30%;
  }
  .el-select{
    width: 100%;
  }
  .el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 100%;
  }
}
</style>

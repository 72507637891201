<template>
  <div class="box">

    <ul>
      <template v-for="(item,index) in components_list" >
        <template v-if="item.pageNumber === pageNumber" >
          <li :key="index">
            <component :ref="item.ref" :is="item.components" ></component>
          </li>
        </template>
      </template>
    </ul>


    <div class="but">
      <el-button size="small" :disabled="previousStepDisabled" @click="previousStep" type="primary" >上一步</el-button>
      <el-button size="small" :loading="nex_step_loading" :disabled="nextStepDisabled" @click="nextStep" type="primary" >{{ nextStepText }}</el-button>
      <el-button size="small" @click="go_back" >取消</el-button>
    </div>

  </div>
</template>

<script>
import basicInfo from "./pageNumber/basic-info";
import partOne from "./pageNumber/part-one";
import partTwoInfo from "./pageNumber/part-two-info";
import tharmResult from "./pageNumber/tharm-result";
import nutrition from "./pageNumber/nutrition";
import nutritionResult from "./pageNumber/nutrition-result";
import physique from "./pageNumber/physique";
import physiqueResult from "./pageNumber/physique-result";
export default {
  name: "subjectAllDetails",
  components: {
    basicInfo,
    partOne,
    partTwoInfo,
    tharmResult,
    nutrition,
    nutritionResult,
    physique,
    physiqueResult,
  },
  data() {
    return {
      components_list: [
        {
          components: basicInfo,
          ref: "basicInfo",
          pageNumber: 0,
          questionnaireType: "",
        },
        {
          components: partOne,
          ref: "partOne",
          pageNumber: 1,
          questionnaireType: "0",
        },
        {
          components: partTwoInfo,
          ref: "partTwoInfo",
          pageNumber: 2,
          questionnaireType: "1",
        },
        {
          components: tharmResult,
          ref: "tharmResult",
          pageNumber: 3,
          questionnaireType: "",
        },
        {
          components: nutrition,
          ref: "nutrition",
          pageNumber: 4,
          questionnaireType: "2",
        },
        {
          components: nutritionResult,
          ref: "nutritionResult",
          pageNumber: 5,
          questionnaireType: "",
        },
        {
          components: physique,
          ref: "physique",
          pageNumber: 6,
          questionnaireType: "3",
        },
        {
          components: physiqueResult,
          ref: "physiqueResult",
          pageNumber: 7,
          questionnaireType: "",
        },
      ],
      pageNumber: 0,
      userId: "",
      docId: "",
      nex_step_loading: false,
    }
  },
  computed: {
    refName() {
      let str = "",
          arr = this.components_list.filter(item => item.pageNumber === this.pageNumber);
      if(arr.length > 0) {
        str = arr[0].ref;
      }
      return str;
    },
    questionnaireType() {
      let str = "",
          arr = this.components_list.filter(item => item.pageNumber === this.pageNumber);
      if(arr.length > 0) {
        str = arr[0].questionnaireType;
      }
      return str;
    },
    previousStepDisabled() {
      let flag = false;
      if(this.pageNumber === 0) {
        flag = true;
      }
      return flag;
    },
    nextStepDisabled() {
      let flag = false;
      // if(this.pageNumber === 7) {
      //   flag = true;
      // }
      return flag;
    },
    nextStepText() {
      let str = "下一步";

      if(this.pageNumber === 7) {
        str = "完成";
      }

      return str;
    }
  },
  provide: function () {
    return {
      userId: () => this.userId,
      docId: () => this.docId
    }
  },
  created() {
    if(this.$route.query.userId) {
      this.userId = this.$route.query.userId;
      this.docId = this.$route.query.docId;
      this.pageNumber = parseInt(this.$route.query.pageNumber);
    }
  },
  mounted() {

  },
  beforeDestroy() { // 销毁前
    this.save();
  },
  methods: {
    save(Fn) {
      switch (this.pageNumber) {
        case 1:
          this.save_api(Fn);
          break;
        case 2:
          this.save_api(Fn);
          break;
        case 4:
          this.save_api(Fn);
          break;
        case 6:
          this.save_api(Fn);
          break;
      }
    },
    save_api(Fn) {
      let obj = {
        questionnaireResultsDTOS: [...this.$refs[this.refName][0].save()],
        pageNumber: this.pageNumber,
        commitType: "0",
        questionnaireType: this.questionnaireType,
        userId: this.userId
      }
      this.$post("gp/commitQuestionnaire",obj)
        .then(res => {
          if(res) {
            console.log(res)
            if(Fn) {
              Fn();
            }
          }
        })
    },
    onSubmitApi() {
      this.nex_step_loading = true;
      this.$refs[this.refName][0].onSubmit()
          .then(res => {
            console.log(res);
            this.$post("gp/commitQuestionnaire",{
              pageNumber: this.pageNumber,
              questionnaireResultsDTOS: res,
              commitType: "1",
              questionnaireType: this.questionnaireType,
              userId: this.userId
            })
              .then(res_ => {
                if(res_) {
                  console.log(res_)
                  this.pageNumber++;
                }
              })
              .finally(() => {
                this.nex_step_loading = false;
              })
          })
          // eslint-disable-next-line no-unused-vars
          .catch(err => {
            this.$message.error("有必填项未填写");
          })
          .finally(() => {
            this.nex_step_loading = false;
          })
    },
    previousStep() { // 上一步
      switch (this.pageNumber) {
        case 0:
          break;
        case 1:
          this.save(this.previousStep_Fn);
          break;
        case 2:
          this.save(this.previousStep_Fn);
          break;
        case 3:
          this.previousStep_Fn();
          break;
        case 4:
          this.save(this.previousStep_Fn);
          break;
        case 5:
          this.previousStep_Fn();
          break;
        case 6:
          this.save(this.previousStep_Fn);
          break;
        case 7:
          this.previousStep_Fn();
          break;
      }
    },
    previousStep_Fn() {
      this.pageNumber--;
    },
    nextStep() { // 下一步
      switch (this.pageNumber) {
        case 0:
          this.$refs[this.refName][0].onSubmit()
              .then(res => {
                if(res) {
                  console.log(res)
                  if(!this.userId) {
                    this.userId = res.id;
                  }
                  this.pageNumber++;
                }
              })
          break;
        case 1:
          this.onSubmitApi();
          break;
        case 2:
          this.onSubmitApi();
          break;
        case 3:
          this.$refs[this.refName][0].onSubmit()
              .then(res => {
                if(res) {
                  this.pageNumber++
                }
              })
          break;
        case 4:
          this.onSubmitApi();
          break;
        case 5:
          this.$refs[this.refName][0].onSubmit()
              .then(res => {
                if(res) {
                  this.pageNumber++
                }
              })
          break;
        case 6:
          this.onSubmitApi();
          break;
        case 7:
          this.$refs[this.refName][0].onSubmit()
              .then(res => {
                if(res) {
                  this.go_back();
                }
              })
          break;
      }
    },
    go_back() {
      this.$router.go(-1);
    },
  }
}
</script>

<style lang="scss" scoped >
.box{
  padding: 24px;
  background: #FFFFFF;
}

.el-form{
  .content{
    width: 30%;
  }
  .el-select{
    width: 100%;
  }
  .el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 100%;
  }
}
.but{
  display: flex;
  justify-content: flex-end;
}
</style>

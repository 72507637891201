<template>
  <div>
    <formBox ref="formBoxRef" v-model="params" />
  </div>
</template>

<script>
import formBox from "@/components/TestKit/components/form-box";

export default {
  name: "physique",
  components: {
    formBox
  },
  data() {
    return  {
      params: {
        domains: []
      }
    }
  },
  inject: ["userId"],
  created() {
    this.get_details();
  },
  mounted() {

  },
  methods: {
    save() {
      return this.$refs["formBoxRef"].save();
    },
    onSubmit() {
      return new Promise((resolve, reject) => {
        this.$refs["formBoxRef"].onSubmit()
            .then(res => {
              resolve(res);
            })
            .catch(err => {
              reject(err);
            })
      })
    },
    get_details() {
      this.$post("gp/getQuestionnaire",{
        questionnaireType: "3",
        userId: this.userId()
      })
          .then(res =>{
            if (res) {
              console.log(res);
              let data_ = res.data;
              this.params['domains'] = data_.map(item => {
                switch(item.type) {
                  case "1": // 多选
                    item.answerId = (item.answerId.split(",") || []).filter(item => item != "");
                    break;
                }

                item["childQuestions"] = {
                  domains: (item.childQuestions || []).map(item_ => {
                    switch(item_.type) {
                      case "1": // 多选
                        item_.answerId = (item_.answerId.split(",") || []).filter(item => item != "");
                        break;
                    }
                    return {
                      ...item_,
                    }
                  })
                }
                return {
                  ...item,
                }
              });

              console.log(this.params.domains);

            }
          })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>

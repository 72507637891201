<template>
  <div>
    <el-form ref="formRef"
             label-width="100px"
             :model="form"
             :rules="rules"
             hide-required-asterisk
             @submit.native.prevent
             size="small" >
      <div class="content">
        <el-form-item label="问卷结果" prop="results" >
          <el-select v-model="form.results" clearable placeholder="请选择">
            <el-option
                v-for="item in option_arr"
                :key="item.key"
                :label="item.value"
                :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="建议" prop="recommend">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              v-model="form.recommend"
              clearable
              placeholder="请输入建议"></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "nutrition-result",
  data() {
    return  {
      form: {
        results: "",
        recommend: "",
      },
      rules: {
        results: [
          { required: true, message: '请选择', trigger: 'change' }
        ]
      },
      pageNumber: 5,
      option_arr: [
        {
          key: "2",
          value: "需要干预"
        },
        {
          key: "3",
          value: "不需要干预"
        }
      ]
    }
  },
  inject: ["userId"],
  created() {
    this.get_details();
  },
  mounted() {

  },
  methods: {
    onSubmit() {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validate((valid) => {
          if(valid) {
            this.$post("gp/commitDoctorRecommend",{
              ...this.form,
              pageNumber: this.pageNumber,
              userId: this.userId(),
            })
                .then(res => {
                  if(res) {
                    resolve(res);
                  }else {
                    reject(valid)
                  }
                })
                .catch(err => {
                  reject(err);
                })
          }else {
            reject(valid)
          }
        })
      })
    },
    get_details() {
      this.$post("gp/getResultPage",{
        pageNumber: this.pageNumber,
        userId: this.userId()
      })
          .then(res => {
            if(res) {
              console.log(res);
              let data_ = res.data;

              // eslint-disable-next-line no-unused-vars
              data_.forEach((item,index) => {
                if(item.pageNumber === this.pageNumber) {
                  this.form.results = item.results;
                  this.form.recommend =  item.recommend;
                }
              })

            }
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form{
  .content{
    width: 30%;
  }
  .el-select{
    width: 100%;
  }
  .el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 100%;
  }
}
</style>
